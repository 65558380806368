import * as React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { StaticImage } from 'gatsby-plugin-image';
import Tilty from 'react-tilty';

// Components
import Layout from '../components/layout/Layout';
import ServiceCard from '../components/card/Service';
import TestimonialsSection from '../components/section/Testimonials';
import ContactSection from '../components/section/Contact';

const InterventionZonePage = () => {

  const query = useStaticQuery(graphql`
    query {
      site: site {
        siteMetadata {
          title
          description
          addressStreet
          addressPostalCode
          addressCity
          addressCountry
          addressGoogleDirection
          email
          telephone
          telephoneIndex
          schedules
          socialNetworks {
            facebook
            instagram
            linkedIn
          }
          author {
            name
            url
            creator
            image {
              url
            }
          }
          publisher {
            name
            url
            logo {
              url
            }
          }
        }
      }
      headerBackgroundImage: file(relativePath: { eq: "headers/equipe-mousse-gava.png" }) {
        childImageSharp {
          fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      cities: allCitiesCsv (
        filter: { active: { eq: "1" } }
      ) {
        nodes {
          id
          name
          name_capital
          name_lowercase
          name_slug
          department
          postal_code
          population
          longitude
          latitude
          active
        }
        totalCount
      }
      services: allMdx(
        filter: {
          fileAbsolutePath: { regex: "/data/local/services/" }, 
          frontmatter: { active: { eq: true } }
        },
        sort: {fields: frontmatter___order, order: ASC}
      ) {
        nodes {
          frontmatter {
            title
            description
            image {
              icon {
                childImageSharp {
                  gatsbyImageData
                  fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              cover {
                childImageSharp {
                  gatsbyImageData
                  fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              card {
                childImageSharp {
                  gatsbyImageData
                  fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              alt
              credit {
                text
                link
              }
            }
            home
            active
            date(formatString: "D MMMM YYYY", locale: "fr")
          }
          id
          slug
        }
        totalCount
      }
    }
  `)

  // Set ImageData.
  const headerBackgroundImage = query.headerBackgroundImage.childImageSharp.fluid
  const headerBackgroundImageStack = [
    headerBackgroundImage,
    `linear-gradient(to bottom, rgba(51, 51, 51, 0.5), rgba(51, 51, 51, 0.1))`,
  ].reverse()

  return (
    <Layout
      pageTitle={`Notre zone d'intervention | ${query.site.siteMetadata.title} - Plâtrerie-peinture, décoration, carrelage, faïence, sols souples, façade et isolation intérieure à Roanne`}
      pageDescription={`L'équipe dynamique et expérimentée de ${query.site.siteMetadata.title} à votre service depuis 70 ans intervient dans un rayon de 40 km autour de Roanne.`}
      pageKeywords="moussé gava, zone d'intervention, témoignages, avis, loire, rhône, travaux, plâtrerie, peinture"
      headerClassName="wpo-site-header wpo-header-style-2"
    >
      <BackgroundImage
        Tag="section"
        className="wpo-page-title"
        fluid={headerBackgroundImageStack}
      >
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <div className="wpo-breadcumb-wrap">
                <h2>Notre zone d'intervention</h2>
                <ol className="wpo-breadcumb-wrap">
                  <li><Link to={`/`}>Accueil</Link></li>
                  <li>Zone d'intervention</li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div id="intervention-zone" className="wpo-about-area section-padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-12 col-sm-12">
              <div className="wpo-about-text">
                <div className="wpo-about-title">
                  <span>Depuis 1953</span>
                  <h2>À votre service</h2>
                </div>
                <div className="h5">{query.site.siteMetadata.title} intervient dans un rayon de 40km autour de Roanne pour vos projets en plâtrerie, peinture, décoration, carrelage, faïence, sols souples, façade et isolation thermique par l'intérieure.</div>
                <p>Forts de notre expérience, nous avons acquis une notoriété ainsi qu'une reconnaissance dans notre métier.</p>
                <ul className="ab-list">
                  <li>Partenaire CEE (Certificats d'Efficacité Energétique).</li>
                  <li>Labellisée RGE (Reconnu garant de l'environnement).</li>
                  <li>Intervient dans un rayon de 40km.</li>
                  <li>Propose des services à la carte.</li>
                </ul>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-12">
              <div className="wpo-about-img">
                <Tilty className="tilty" glare scale={1.05} maxGlare={0.5}>
                  <StaticImage src="../images/intervention-zone/zone-d-intervention-mousse-gava.png" alt={`Zone d'intervention de ${query.site.siteMetadata.title} dans un rayon de 40km autour de Roanne : Le Coteau, Riorges, Perreux, Mably, Commelle-Vernay, Villerest, Saint-Léger-sur-Roanne, Parigny, Ouches, Pouilly-les-Nonains, Lentigny`} />
                </Tilty>
              </div>
              <div className="text-center mt-3">
                <div className="btns">
                  <Link to={`/contact/`} className="theme-btn">Contactez-nous</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {query.cities && query.cities.nodes.length ?
        <>
          {query.cities.nodes.map((city, index) => (
            <div id="services" className={index % 2 === 0 ? "wpo-service-area section-padding bg-light" : "wpo-service-area section-padding"}>
              <div className="container">
                <div className="row">
                  <div className="wpo-section-title">
                    <h2>Nos services à {city.name}</h2>
                  </div>
                </div>
                <div className="row align-items-top">
                  {query.services.nodes.map(service => (
                    <ServiceCard key={service.id} data={service} city={city} cardStyle="2" cardClassName="col-lg-4 col-md-6 col-12 mb-4" />
                  ))}
                </div>
              </div>
            </div>
          ))}
        </>
        : null}
      <TestimonialsSection site={query.site} sectionStyle="1" />
      <ContactSection site={query.site} sectionStyle="1" />
    </Layout>
  )
}

export default InterventionZonePage
