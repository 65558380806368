import * as React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import slugify from '@sindresorhus/slugify';

const ServiceCard = ({ data, city, cardStyle, cardClassName }) => {
  return (
    <>
      {cardStyle === "1" &&
        <div key={data.id} className={cardClassName} data-aos="fade-up" data-aos-delay="0" data-aos-offset="-50">
          <Link to={`/service/${data.slug}`} aria-label={data.frontmatter.image.alt}>
            <div className="wpo-service-item h-100">
              <GatsbyImage
                className="icon"
                image={getImage(data.frontmatter.image.icon)}
                alt={data.frontmatter.image.alt}
              />
              {data.frontmatter.title &&
                <h2>{data.frontmatter.title}</h2>
              } 
              {data.frontmatter.description &&        
                <p className="desc">{data.frontmatter.description}</p>
              }
              <Link to={`/service/${data.slug}`} aria-label={data.frontmatter.image.alt}>Voir plus...</Link>
            </div>
          </Link>
        </div>
      }
      {cardStyle === "2" &&
        <div key={data.id} className={cardClassName} data-aos="fade-up" data-aos-delay="0" data-aos-offset="-50">
          <Link to={`/service/${slugify(city.name) + '-' + data.slug}`} aria-label={data.frontmatter.image.alt.replaceAll("{{city_name}}", city.name).replaceAll("{{department}}", city.department).replaceAll("{{postal_code}}", city.postal_code)}>
            <div className="wpo-service-item h-100">
              <GatsbyImage
                className="icon"
                image={getImage(data.frontmatter.image.icon)}
                alt={data.frontmatter.image.alt.replaceAll("{{city_name}}", city.name).replaceAll("{{department}}", city.department).replaceAll("{{postal_code}}", city.postal_code)}
              />
              {data.frontmatter.title &&
                <h2>{data.frontmatter.title}</h2>
              } 
              {data.frontmatter.description &&        
                <p className="desc">{data.frontmatter.description.replaceAll("{{city_name}}", city.name).replaceAll("{{department}}", city.department).replaceAll("{{postal_code}}", city.postal_code)}</p>
              }
              <Link to={`/service/${slugify(city.name) + '-' + data.slug}`} aria-label={data.frontmatter.image.alt.replaceAll("{{city_name}}", city.name).replaceAll("{{department}}", city.department).replaceAll("{{postal_code}}", city.postal_code)}>Voir plus...</Link>
            </div>
          </Link>
        </div>
      }
    </>
  )
}

export default ServiceCard